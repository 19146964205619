/**
 * Created by Jerry on 26.10.2015.
 */
$(document).ready(function(){

    function addedToCartAnim () {

        var cart = $('#cart-btn').find('#right-side');
        var imgtodrag = $(".image-thumb").find("a.slick-active").find("img").eq(0);
        if (imgtodrag) {
            var imgclone = imgtodrag.clone()
                .offset({
                    top: imgtodrag.offset().top,
                    left: imgtodrag.offset().left
                })
                .css({
                    'opacity': '0.5',
                    'position': 'absolute',
                    'height': '200px',
                    'width': '200px',
                    'z-index': '1005'
                })
                .appendTo($('body'))
                .animate({
                    'top': cart.offset().top + 10,
                    'left': cart.offset().left + 10,
                    'width': 75,
                    'height': 75
                }, 1000, 'easeInOutExpo');

            setTimeout(function () {
                cart.find('i').switchClass('fa-shopping-cart', 'fa-check');
                setTimeout(function () {
                    cart.find('i').switchClass('fa-check', 'fa-shopping-cart');
                }, 2000);
            }, 1000);

            imgclone.animate({
                'width': 0,
                'height': 0
            }, function () {
                $(this).detach()
            });
        }
    }

    function loadCart(data) {

        $('#cart-total-price').html(data.total_prices.display.price_wc);
        if (data.rows.length > 0) {
            $('#cart-btn').find('#left-side').html(data.total_prices.display.price_wc);
            $('#cart-empty').hide();
            $('#cart-contents').show();
        } else {

            $('#cart-btn').find('#left-side').html(phpvar.sEmptyCart);
            $('#cart-empty').show();
            $('#cart-contents').hide();
        }

        jPut.cart.data = data.rows;

        $('a.cart-remove-item-btn').on('click', function(e) {
            e.preventDefault();
            var id = $(this).attr('data-id');
            removeItemFromCart(id);
        });

    }

    //Load cart on page load

    var cart_url = "/cart/get";
    $.getJSON(cart_url, function(data) {
        loadCart(data);

    });


    $('#add-to-cart').on('click', function(){
        var qty = $('#qty-input').val();
        var id = $(this).attr('data-id');
        var url = "/cart/add/" + id + "?qty=" + qty;
        $(".ajax-loader").fadeTo(500,1);
        $.getJSON(url, function(data) {
            loadCart(data);
            $(".ajax-loader").fadeTo(500,0);
            addedToCartAnim();
        });
    });

    function removeItemFromCart(id) {
        var url = "/cart/remove/" + id;
        $(".ajax-loader").fadeTo(500,1);
        $.getJSON(url, function(data){
            loadCart(data);
            $(".ajax-loader").fadeTo(500,0);
        });
    }




    var timer;
    var delay = 1100;
    $('body').on('input', 'input.cart-update-qty', function() {
        var id = $(this).attr('data-id');
        var qty = $(this).val();
        clearTimeout(timer);
        timer = setTimeout(function(){
            if (qty === "0") {
                removeItemFromCart(id);
            } else {

                var url = "/cart/update/" + id + "?qty=" + qty;
                $(".ajax-loader").fadeTo(500,1);
                $.getJSON(url, function(data) {
                    loadCart(data);
                    $(".ajax-loader").fadeTo(500,0);
                });
            }


        }, delay);

    });


    $('#cart-btn #left-side, #cart-btn #right-side').on('click', function(event) {
        event.stopPropagation();
        var el = $('#cart-overview');
        if (el.data('shown') == '0') {
            el.slideDown( "slow" );
            el.data('shown', 1);
        } else {
            el.slideUp('slow');
            el.data('shown', 0);
        }

    })

    $('html').click(function() {
        var el = $('#cart-overview');
        if (el.data('shown') == '1') {
            el.slideUp('slow');
            el.data('shown', 0);
        }
    });

    $('#cart-overview').click(function(event){
        event.stopPropagation();
    });

    $('#currency-select').on('change', function() {
        $(".ajax-loader").fadeTo(500,1);
        var id = $(this).val();
        var url = '/set/currency/' + id;
        window.location.replace(url);
    });


    //Checkout




    $('.ss_button').on('click',function() {
        $('.ss_content').slideUp('slow');
        $(this).next('.ss_content').slideDown('slow');
        $('input[name="shipping_type"]').prop('checked', false);
        $(this).find('input').prop('checked', true);

        if ($(this).hasClass('type-instore')) {
            $('#ship-addr-form').validator('destroy');
            $('#ship-addr-form').attr('novalidate', 'novalidate');
        } else {
            $('#ship-addr-form').validator();
            $('#ship-addr-form').removeAttribute('novalidate');
        }

    });

    // Get the view for default shipping type

    if ($('input[name="shipping_type"]').length) {

        $('.ss_button.type-ship').trigger('click');

    }

    $('.method-select').on('click', function() {
        $(this).find('input').prop('checked', true);
    });

    $('.back-one-step').on('click', function() {
        window.history.back();
    });

});