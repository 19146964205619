/**
 * Created by Jerry on 19.10.2015.
 */

function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function setCookie(key, value) {
    setCookieForDays(key, value, 14);
}
function setCookieForDays(key, value, days) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + ';path=/';
}

function getCookie(key) {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

$(document).ready(function(){


    $('.account-button').hover(
        function() {
            $(this).find('i').addClass('hover');
            $(this).find('.left-side-btn').addClass('hover');
        },
        function() {
            $(this).find('i').removeClass('hover');
            $(this).find('.left-side-btn').removeClass('hover');
        }

    );

    if (inIframe()) {
        $('body').addClass('in-iframe');
    }

    $('#login-btn').on('click',
        function() {
            var el = $('#login-div');
            if (el.data('shown') == '0') {
                el.slideDown( "slow" );
                el.data('shown', 1);
            } else {
                el.slideUp('slow');
                el.data('shown', 0);
            }
        });

    $('[data-toggle="tooltip"]').tooltip();
    $('.clickable-row').on('click', function() {
        window.document.location = $(this).data("href");
    });

    $('#history-back-btn').on('click', function() {

        if (document.referrer.indexOf(window.location.host) !== -1)
        {
            history.go(-1); return false;
        } else {
            $(this).css('background-color', '#BBB');
            $(this).removeClass('hvr-skew-forward');
        }
    });

    new SmartBanner({
        daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        title: 'DT Technologies',
        author: 'DT Technologies CZ s.r.o.',
        button: 'DOWNLOAD',
        store: {
            ios: 'On the App Store',
            android: 'In Google Play',
        },
        price: {
            ios: 'FREE',
            android: 'FREE'
        }
        // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
        // , icon: '' // full path to icon image if not using website icon image
        // , force: 'ios' // Uncomment for platform emulation
    });

    if(getCookie('gdpr_consent_dismissed') != 1) {
        $('.gdpr-consent-overlay').show();
    }

    $('.gdpr-consent-overlay .close-button').on('click', function() {
        setCookieForDays('gdpr_consent_dismissed', 1, 365);
        $('.gdpr-consent-overlay').slideUp();
    });


});