/**
 * Created by Jerry on 15.10.2015.
 */

$(document).ready(function(){

    $('.item-wrapper').mouseenter(function() {
        $(this).find('.item-img-wrapper').addClass('hover');
        $(this).find('.item-title').addClass('hover');
    });

    $('.item-wrapper').mouseleave(function() {
        $(this).find('.item-img-wrapper').removeClass('hover');
        $(this).find('.item-title').removeClass('hover');
    });

    $('.image-thumb').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.image-slider',
        responsive: true

    });
    $('.image-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.image-thumb',
        dots: true,
        centerMode: true,
        focusOnSelect: true,
        infinite: false,
    });



    if ( $( "#other-variants" ).length ) {
        $('#variant-select').perfectScrollbar();

        var variantList = new List('other-variants', {
            valueNames: ['variant-title', 'variantProfileHidden'],
            page: 2000
        });


        // // Select default first filter
        // var selectedProfile = 'D';
        // variantList.filter(function(item) {
        //     var profile = item.values().variantProfileHidden.trim();
        //     if (profile == selectedProfile) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // });

    }



    $('.variant-profile-filter').on('click', function() {
        var selectedProfile = $(this).data('code');

        if (selectedProfile == 'remove') {
            variantList.filter();
            $('.variant-profile-filter').find('img').removeClass('highlight');
        } else {
            $('.variant-profile-filter').find('img').removeClass('highlight');
            $(this).find('img').addClass('highlight');

            variantList.filter(function(item) {
                var profile = item.values().variantProfileHidden.trim();
                if (profile == selectedProfile) {
                    return true;
                } else {
                    return false;
                }
            });
        }

    });

    $('.variant-line').hover(function() {
        $(this).find('td ,a').addClass('hover');
    }, function(){
        $(this).find('td ,a').removeClass('hover');
    });



    $('.image-thumb').magnificPopup({
        delegate: 'a', // child items selector, by clicking on it popup will open
        type: 'image',
        gallery:{
            enabled:true
        },
        zoom: {
            enabled: true, // By default it's false, so don't forget to enable it

            duration: 300, // duration of the effect, in milliseconds
            easing: 'ease-in-out', // CSS transition easing function

            // The "opener" function should return the element from which popup will be zoomed in
            // and to which popup will be scaled down
            // By defailt it looks for an image tag:
            opener: function(openerElement) {
                // openerElement is the element on which popup was initialized, in this case its <a> tag
                // you don't need to add "opener" option if this code matches your needs, it's defailt one.
                return openerElement.is('a') ? openerElement : openerElement.find('img');
            }
        }

        // other options
    });

    $(".magnify img").easyZoom({
        selector: {
            preview: "#preview-zoom",
            window: "#window-zoom"
        }
    });

    // $('[data-slide-toggle]').on('click', function () {
    //     var target = $(this).attr('data-slide-toggle');
    //     $(target).slideToggle();
    // })



});